import React from 'react';
import { LanguageProvider } from './LanguageProvider';

const RootLayout = ({ children }) => {
  return (
    <>
      <LanguageProvider>{children}</LanguageProvider>
    </>
  );
};

export default RootLayout;
