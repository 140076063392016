import React, { createContext, useState } from 'react';

export type Languages = 'en' | 'de';

// Define the initial state for the language context
const initialState = {
  language: 'en',
  setLanguage: (lang: string) => {},
};

// Create the language context
export const LanguageContext = createContext(initialState);

// Create the language provider component
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(initialState.language);

  // Update the language value and re-render the component tree
  const handleSetLanguage = (lang: string) => {
    setLanguage(lang);
  };

  return (
    <LanguageContext.Provider
      value={{ language, setLanguage: handleSetLanguage }}
    >
      {children}
    </LanguageContext.Provider>
  );
};
